<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "./widget.vue";
import IssueChart from "./task-chart.vue";

import Issues from "./active_produce.vue";


import {useTaskApi} from '@/apis/task'


/**
 * Sales-Dashboard component
 */
export default {
  page: {
    title: 'dashboard.main_page.sub_title',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: this.$t('dashboard.main_page.sub_title'),
      items: [
        {
          text: this.$t('dashboard.main_page.sub_title'),
        },
       
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    IssueChart,
    Issues,
  },

  mounted() {
    this.query_task_statistic()
  },

  methods: {
    query_task_statistic() {
      useTaskApi().task_statistic().then(res => {
        console.log(res)
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat />
    <div class="row">
      
      <div class="col-xl-4">


       

        <IssueChart />
      </div>
      
      <!-- end col-->


      <Issues />
    </div>
  
  </Layout>
</template>
