<script>

import { useTaskApi } from '@/apis/task'


export default {


  data() {


    return {
      total_count: 0,
      progress_count: 0,
      done_count: 0,
      discard_count: 0,

      latest_task : {},
      earningReport: {
        series: [0, 0, 0, 0],
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          chart: {
            height: 130,
            type: "donut",
          },
          labels: ["总计", "进行中", "已完成", "已废弃"],
          colors: ["#038edc", "#dfe2e6", "#5fd0f3"],
          legend: {
            show: false,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
          },
        },
      },

      revenueChart: {
        series: [
          {
            data: [10, 20, 15, 40, 20, 50, 70, 60, 90, 70, 110],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 50,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            y: {
              title: {
                formatter: function () {
                  return "";
                },
              },
            },
          },
          colors: ["#038edc"],
        },
      },
    };
  },


  mounted() {
    useTaskApi().task_statistic().then(res => {
      console.log(res)

      if (res.err == 0) {
        let total = res.data.reduce((a, c) => a + Number(c.count), 0)
        this.earningReport.series = [total]

        let progress = res.data.find(e => e.status == 'SUBMITTED')
        let done = res.data.find(e => e.status == 'DONE')
        let discard = res.data.find(e => e.status == 'DELETED')

        this.earningReport.series.push(progress ? Number(progress.count) : 0)
        this.earningReport.series.push(done ? Number(done.count) : 0)
        this.earningReport.series.push(discard ? Number(discard.count) : 0)

        this.total_count = total
        this.progress_count = progress ? Number(progress.count) : 0
        this.done_count = done ? Number(done.count) : 0
        this.discard_count = discard ? Number(discard.count) : 0

      }
    })

    useTaskApi().list_task({page : {page_no : 1 , page_size : 1}}).then(res => {
      if (res.err == 0) {
        if (res.data.length > 0) {
          this.latest_task = res.data[0]
        }
      }
    })

    
  }
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">

        <div class="row align-items-center">
          <div class="col-sm-7">
            <p class="font-size-14">
              最新投产任务:

            </p>
            <p class="font-size-14">
              <span class="fw-semibold">任务编号: {{ this.latest_task.task_number }}</span>
            </p>
            <p class="font-size-14">
              <span class="fw-semibold">投产日期: {{ this.latest_task.task_date }}</span>
            </p>
            <div class="mt-4">
              <router-link :to="'/produce/show_task?parent_task=' +  this.latest_task.task_number" class="btn btn-primary">查看任务详情</router-link>
            </div>
          </div>
          <div class="col-sm-5">
            <img src="@/assets/images/illustrator/2.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card -->


    <div class="card">
      <div class="card-body">
        <div class="float-end">
        </div>

        <h4 class="card-title mb-4">任务统计</h4>

        <div class="row align-items-center">
          <div class="col-sm-7">
            <div class="row mb-3">
              <div class="col-4">
                <p class="text-muted mb-2">总计</p>
                <h5>
                  {{ total_count }}
                </h5>
              </div>

              <div class="col-4">
                <p class="text-muted mb-2">进行中</p>
                <h5>{{ progress_count }}</h5>
              </div>
              <div class="col-4">
                <p class="text-muted mb-2">已完成</p>
                <h5>{{ done_count }}</h5>
              </div>
              <div class="col-4">
                <p class="text-muted mb-2">已废弃</p>
                <h5>{{ discard_count }}</h5>
              </div>
            </div>
          </div>
          <!-- end col-->
          <div class="col-sm-5">
            <div class="mt-4 mt-0">
              <apexchart class="apex-charts" height="130" dir="ltr" :series="earningReport.series"
                :options="earningReport.chartOptions"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->

  </div>
</template>
