<script>

import {useTaskApi} from '@/apis/task'



export default {
  data() {
    return {
      tableData : [],
    };
  },

  mounted() {
    
    useTaskApi().active_produce_query().then(res => {
        console.log(res)
        if (res.err == 0) {
          res.data.map(e => {
            this.tableData.push(e)
          })
        }
        
      })
  
  },


  methods: {
    initCheckAll() {
        const checkAll = document.getElementById("checkAll");
        if (checkAll) {
            checkAll.onclick = function() {
                const checkboxes = document.querySelectorAll('.table-check input[type="checkbox"]');
                for(var i=0; i < checkboxes.length; i++) {
                    checkboxes[i].checked = this.checked;
                }
            }
        }
    }
  }
};
</script>

<template>
  <div class="col-xl-8">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h4 class="card-title mb-4">加工型号</h4>
          <div>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table table-hover table-nowrap mb-0 align-middle table-check"
          >
            <thead class="bg-light">
              <tr>
                
                <th>型号</th>
                <th>名称</th>
                <th>转入日期</th>
                <th>投产数量</th>
                <th>转出数量</th>
               
              </tr>
              <!-- end tr -->
            </thead>
            <!-- end thead -->
            <tbody>
              <tr v-for="(item, index) in tableData" :key="index">
                <td>
                  {{item.model_code}}
                </td>
                <td class="fw-medium">
                  {{item.model_name}}
                </td>
                <td>
                  {{item.task.task_date}}
                </td>
                <td>
                  {{item.i_count}}
                </td>
                <td>
                  {{item.o_count}}
                </td>
             
              </tr>
              <!-- end /tr -->
             
            </tbody>
            <!-- end tbody -->
          </table>
          <!-- end table -->
        </div>
      </div>
    </div>
  </div>
</template>
